<template>
  <section class="keyword-wrapper">
    <el-form class="keyWordForm" :model="form" label-width="80px" :rules="rules" ref="form">
      <el-form-item label="主题名称" prop="subjectName">
        <el-select v-model="form.subjectName" placeholder="请选择主题">
          <el-option label="主题一" value="shanghai"></el-option>
          <el-option label="主题二" value="beijing"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="分类名称" prop="categoryName">
        <el-select v-model="form.categoryName" placeholder="请选择分类">
          <el-option label="分类一" value="shanghai"></el-option>
          <el-option label="分类二" value="beijing"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="核心词" class="el-textarea">
        <textarea v-model="form.coreWords"></textarea>
        <span class="rule">核心词用于分类依据，文章包含以上任一核心词，则系统判别为属于该主题。词与词之间用、号连接。例如：微软、苹果。</span>
        <span class="rule">英文字母区分大小写</span>
      </el-form-item>
      <el-form-item label="同现词" class="el-textarea">
        <textarea v-model="form.homonymWords"></textarea>
        <span class="rule">同现词，必须与核心词同时出现的词，词与词之间用、号连接。</span>
        <span class="rule">英文字母区分大小写</span>
      </el-form-item>
      <el-form-item label="排除词" class="el-textarea" prop="exclusionWords">
        <textarea v-model="form.exclusionWords"></textarea>
        <span class="rule">排除词用于分类时限制无关信息被判别到该主题下。当排除词在核心词附近的前后出现时，系统将认为这条信息虽然含有核心词但仍然是无关信息而从避免将其分类至该主题下，词与词之间用、号连接。</span>
        <span class="rule">英文字母区分大小写</span>
      </el-form-item>
      <el-form-item size="large">
        <el-button size="small" @click="goBack">返回</el-button>
        <el-button type="primary" @click="onSubmit" size="small">保存</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>
<script>
import eventHub from '@/utils/eventHub'
export default {
  mounted () {
    if (this.$route.meta.type === 'edit') {
      eventHub.$emit('msg', '修改关键词')
    } else if (this.$route.meta.type === 'add') {
      eventHub.$emit('msg', '创建关键词')
    }
  },
  data () {
    return ({
      form: {
        subjectName: '',
        categoryName: '',
        industry: '',
        coreWords: '',
        homonymWords: '',
        exclusionWords: ''
      },
      rules: {
        subjectName: [{ required: true, message: '请选择主题名称', trigger: 'change' }],
        categoryName: [{ required: true, message: '请选择分类名称', trigger: 'change' }],
        exclusionWords: [{ required: true, message: '排除词不能为空', trigger: 'blur' }]
      }
    })
  },
  methods: {
    goBack () {
      this.$router.push('/settings/project/keywords')
    },
    onSubmit () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$message({
            message: '保存成功',
            type: 'success'
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }

  }
}
</script>

<style lang="scss" scoped>
  .keyword-wrapper {
    width: 100%;
    border-radius: 8px;
    background: #fff;
    padding-top: 50px;

    .title {
      font-size: 16px;
      color: #000000;
      margin: 0 0 20px 20px;
    }

    .el-form {
      // display: flex;
      // flex-wrap: wrap;
      padding: 0 30px;

      .el-form-item {
        .el-form-item__content {
          textarea {
            width: 100%;
            height: 136px;
            border: 1px solid #DCDFE6;
            color: #606266;
            border-radius: 4px;
          }

          .rule {
            color: #8f8f94;
            font-size: 12px;
            line-height: 22px;
            margin: 0;
            display: block;
          }
        }
      }
    }
  }
</style>
